import { registerTag } from '@/custom_elements_loader.ts';

registerTag(
    'x-narrowing-control',
    () => import('@/components/x_narrowing_control.ts')
);

registerTag(
    'x-resource-map',
    () => import('@/components/resource_directory/x_resource_map.ts')
);
