// Do this ASAP so we can catch any errors that occur later
// import '@/boot_sentry';

// Do this next because other things rely on jquery being assigned
// to the global 'window' object (window.$ and window.jQuery)
import '@/boot_jquery';

import autosize from 'autosize';
import 'bootstrap-sass';

// Site-specific libraries
//
import '@/back_to_top';
import '@/custom_elements';
import '@/megamenu_keyboard';
import '@/toggle_leftnav';
import { onReady } from '@/utils';


onReady(() => {
    // Set textareas to auto-resize
    autosize(document.querySelectorAll('textarea'));
});

